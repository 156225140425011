import { Box, FormHelperText, TextField, Typography } from "@mui/material"
import styled from "styled-components"
import theme, { colors } from "../../styles/theme"

export const CheckoutBox = styled(Box)`
  background-color: #ffffff;
  max-width: 870px;
  width: 100%;
  height: 100%;
`

export const CheckoutHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
  border-bottom: 0.5px solid ${colors.gray};

  && h3 {
    font-size: 25px;
  }

  ${theme.breakpoints.down("sm")} {
    height: 64px;
    padding: 0 20px;

    && h3 {
      font-size: 20px;
    }
  }
`

export const FormBox = styled(Box)`
  padding: 32px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 24px 20px;
  }
`

export const CardInformationLabel = styled(Typography)`
  font-size: 20px !important;
  display: flex;
  align-items: center;
  margin-top: -11px !important;
  margin-bottom: -11px !important;
  padding-bottom: 16px !important;

  && img {
    margin-left: 14px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 18px !important;
    margin-top: -5px !important;
    margin-bottom: -4px !important;
    padding-bottom: 20px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`

export const BillingDetailsLabel = styled(Typography)`
  font-size: 20px !important;
  margin-top: -11px !important;
  margin-bottom: -10px !important;
  padding-top: 32px;
  padding-bottom: 16px;

  ${theme.breakpoints.down("sm")} {
    font-size: 18px !important;
    margin: 0 !important;
    padding-bottom: 20px;
  }
`

export const BorderedBox = styled(Box)<{ boxPosition: number }>`
  border: 0.5px solid ${colors.gray};
  padding: 32px 24px 32px;

  && .formRowSpacing:not(:first-child) {
    margin-top: 46px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    border: 0;

    && .formRowSpacing {
      margin-top: 30px;
    }
  }
`

export const FormRow = styled(Box)`
  display: flex;
  gap: 16px;

  && .selectInputField > div {
    padding: 0 32px 0 13px !important;
  }

  ${theme.breakpoints.down("sm")} {
    gap 0px;
    flex-flow: wrap;

    && > div:nth-child(2) {
      margin-top: 46px;
    }
  }
`

export const BlueBox = styled(Box)`
  margin-top: 32px;

  && p {
    margin-top: -4px;
    margin-bottom: -1px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 20px;

    && p {
      font-size: 14px;
    }
  }
`

export const BlueLabel = styled.a`
  color: ${colors.lightBlue};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
`

export const ButtonBox = styled(Box)`
  padding: 32px;
  border-top: 0.5px solid ${colors.gray};
  box-sizing: border-box;
  display: flex;
  gap: 16px;

  && .formSubmitButton:hover {
    background: ${colors.black} !important;
    color: white;
  }

  && .formSubmitButton:hover * {
    border: none !important;
  }

  && .formCancelButton:hover {
    color: ${colors.alert};
    border: 1px solid ${colors.alert} !important;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px;
    flex-flow: column;
  }
`

export const CheckboxBox = styled(Box)`
  padding-top: 24px;
  ${theme.breakpoints.down("sm")} {
    padding-top: 20px;
  }
`

export const StripeElementContainer = styled("div")({
  position: "relative",
  height: "48px",
  boxSizing: "border-box",
  borderRadius: "2px",
  backgroundColor: "#fafafa",
  padding: "10px 0",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    transition: "0.2s",
  },
  "&::before": {
    left: 0,
    right: 0,
    bottom: 0,
    content: '"\\00a0"',
    position: "absolute",
    transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    pointerEvents: "none",
  },
  "&::after": {
    left: 0,
    right: 0,
    bottom: 0,
    content: '""',
    position: "absolute",
    transform: "scaleX(0)",
    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    borderBottom: "2px solid #2d2d2d",
    pointerEvents: "none",
  },
  "&.focused::after, &.error::after": {
    transform: "scaleX(1)",
  },
  "&.error::after": {
    borderBottomColor: "#f44336",
  },
})

export const StripeElement = styled("input")({
  border: "none",
  width: "100%",
  backgroundColor: "transparent",
  padding: "10px",
  fontSize: "16px",
  boxSizing: "border-box",
  animationName: "mui-auto-fill-cancel",
  animationDuration: "10ms",
})

export const ErrorText = styled(FormHelperText)({
  color: "#f44c49 !important",
  fontSize: "14px !important",
  fontWeight: 700,
})

export const FormFlexBox = styled(Box)`
  display: flex;
  gap: 16px;
  width: 100%;

  && .MuiFormControl-marginNormal {
    margin-top: 8px;
  }

  && .MuiFilledInput-input {
    padding: 19px 12px 10px;
  }

  && .MuiFormHelperText-contained {
    margin-left: 0;
  }

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
  }
`

export const FormFlexBoxItem = styled(Box)`
  width: 100%;
`

export const MUIField = styled(TextField)`
margin-top: 0px !important;
margin-bottom: 0px !important;

&& {
  .MuiInputLabel-filled {
    transform: translate(12px, 14px) scale(1);
  }

  .MuiInputLabel-shrink {
    transform: translate(12px, 5px) scale(0.7);
  }

  .MuiFormHelperText-contained {
    margin-right: 14px;
    margin-left: 0px !important;
  }

  .MuiFormHelperText-root.Mui-error {
    color: #f44c49;
    font-size: 14px;
    margin-bottom: -5px;
    font-weight: 400;
  }

  .MuiFilledInput-root.Mui-error:after {
    border-bottom-color: #f44c49;
  }

  input {
    padding: 12.5px 0 12.5px 13px;]
  }
}
`
