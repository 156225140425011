import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../../hooks/useApi"

export type AccountDetails = {
  email: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  accountDetails?: AccountDetails
  status: LoadingStatuses
  error?: string
}

type ValidateOrgDiscountPayload = {
  priceId: string
  orgId?: number
}

export const validateOrgDiscount = createAsyncThunk<
  boolean,
  ValidateOrgDiscountPayload
>("/validateOrgDiscount", async ({ priceId, orgId }) => {
  return useApi(
    null,
    "/v1/guest/are/product/org",
    {
      method: "POST",
      body: JSON.stringify({ priceId, orgId: Number(orgId) }),
    },
    "bks-services"
  ).then(async (res) => {
    const response = await res.json()

    return response.allow
  })
})

const initialState: SliceState = {
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "validateOrgDiscount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateOrgDiscount.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = LoadingStatuses.Succeeded
        } else {
          window.location.href = "https://blackspectacles.com/"
        }
      })
      .addCase(validateOrgDiscount.rejected, (state, action) => {
        state.status = LoadingStatuses.Succeeded
      })
  },
})
