import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { RootState } from "./rootReducer"
import { AuthContextInterface } from "../providers/AuthProvider"
import { LoadingStatuses } from "./tax"

//#region types
export type Members = {
  id: string
  email: string
  screenName: number
  firstName: string
  lastName: string
  phone?: number
  phoneExt?: string
  active: boolean
  createDate: Date
  lastUpdate?: Date
  addresses?: MemberAddess[]
  networkAccounts?: MemberNetworkAccount[]
  access?: MemberAccess
  groupAccounts?: MemberGroupAccount[]
  courseBookmarks?: CourseBookmark[]
  isGroupAdmin?: boolean
  isAREComplete?: boolean
  accessLevel?: string
  lastLogin?: string
  showExpireSurvey?: boolean
}

export type CourseBookmark = {
  courseId: string
  lessonPlanId: string
}

export type MemberGroupAccount = {
  propertyId: string
  id: string
  admin?: boolean
  createDate: string
  organizationId?: number
}

export type MemberAccess = {
  access: any
}

export type MemberNetworkAccount = {
  id: string
  propertyId: string
  createDate: Date
}

export type MemberAddess = {
  id: string
  Address1?: string
  Address2?: string
  city?: string
  state?: string
  postalCode?: string
  country?: string
  primary: boolean
  checkHash?: string
}

type SliceState = {
  member?: Members
  memberStatus: LoadingStatuses
  error: string | null | undefined
  upgradePriceUrl?: string
}
//#endregion

//#region api
type GetMemberPayload = {
  auth: AuthContextInterface
}
export const getMember = createAsyncThunk<any, GetMemberPayload>(
  "member/get",
  async ({ auth }) => {
    const endpoint = `/v1/are/member/profile`

    return useApi(
      auth,
      endpoint,
      {
        method: "GET",
      },
      "bks-services"
    ).then((res) => res.json())
  }
)

type updatePasswordPayload = {
  auth: AuthContextInterface
  firstName: string
  lastName: string
  email: string
  updateEmail: boolean
}

export const updateMemberProfile = createAsyncThunk<any, updatePasswordPayload>(
  "/updateMemberProfile",
  async ({ auth, firstName, lastName, email, updateEmail }) => {
    const endpoint = `/v1/are/member/profile`
    const fields = {
      first_name: firstName,
      last_name: lastName,
      email,
      update_email: !!updateEmail,
    }

    return useApi(auth, endpoint, {
      method: "PATCH",
      body: JSON.stringify(fields),
    }).then((res) => res.status === 200)
  }
)

type GetUpgradePriceIdPayload = {
  auth: AuthContextInterface
}
export const getUpgradePrice = createAsyncThunk<any, GetUpgradePriceIdPayload>(
  "/upgrade/product",
  async ({ auth }) => {
    const endpoint = `/v1/are/member/upgrade/product`

    return useApi(auth, endpoint, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type UpdateBookmarkStatePayload = {
  courseId: string
}
export const updateBookmarkState = createAsyncThunk<
  any,
  UpdateBookmarkStatePayload
>("bookmark/update", () => {
  return ""
})
//#endregion

//#region slice
const initialState: SliceState = {
  member: undefined,
  memberStatus: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "member",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMember.pending, (state) => {
      state.member = undefined
      state.memberStatus = LoadingStatuses.Loading
    })
    builder.addCase(getMember.fulfilled, (state, action) => {
      state.member = action.payload
      state.memberStatus = LoadingStatuses.Succeeded

      if (
        state.member?.groupAccounts &&
        state.member?.groupAccounts.length > 0
      ) {
        const isAdmin = state.member.groupAccounts?.find(
          (member) => member.admin
        )
        state.member = { ...state.member, isGroupAdmin: isAdmin ? true : false }
      }
    })
    builder.addCase(getMember.rejected, (state) => {
      state.member = undefined
      state.memberStatus = LoadingStatuses.Failed
    })
  },
})
//#endregion

//#region selectors
export const selectMember = ({ member }: RootState) => member.member
export const selectMemberAccess = ({ member }: RootState) =>
  member.member?.access?.access
export const selectMemberStatus = ({ member }: RootState) => member.memberStatus
//#endregion
