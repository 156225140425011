import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../../hooks/useApi"

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  nomaValid: boolean
  status: LoadingStatuses
  error?: string
}

type validateEmailPayload = {
  email: string
}

export const validateNomaEmail = createAsyncThunk<any, validateEmailPayload>(
  "/validateNomaEmail",
  async ({ email }) => {
    const fields = {
      email,
    }

    return useApi(null, "/v1/guest/are/member/email", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then(async (res) => {
      return await res.json()
    })
  }
)

const initialState: SliceState = {
  nomaValid: false,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "validateNomaEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateNomaEmail.fulfilled, (state, action) => {
        state.nomaValid = action.payload.noma
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(validateNomaEmail.pending, (state, action) => {
        state.status = LoadingStatuses.Loading
      })
      .addCase(validateNomaEmail.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
