import React, { useState } from "react"
import {
  ButtonBox,
  DialogBox,
  DialogSubtitle,
  DialogText,
  StyledDialog,
} from "./styles"
import { Divider } from "@mui/material"
import { useAppDispatch } from "../../redux/configureStore"
import { Button } from "../Button"
import TextField from "../TextField"
import Label from "../Label"
import {
  LoadingStatuses,
  validateNomaEmail,
} from "../../redux/guest/validateNomaEmail"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/rootReducer"
import CircularProgress from "@mui/material/CircularProgress"

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
}

const View = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const { status } = useSelector((state: RootState) => state.validateNomaEmail)

  const handleSubmit = async () => {
    const { payload, type } = await dispatch(validateNomaEmail({ email }))

    if (type === validateNomaEmail.rejected.type) {
      setError("Failed validating your email!")
      return
    }

    if (type === validateNomaEmail.fulfilled.type) {
      if (payload.noma) {
        toast.success("Validated NOMA Email")
        setOpen(false)
        return
      } else {
        setError("Not a valid NOMA Email!")
      }
    }
  }

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
  }

  return (
    <StyledDialog maxWidth={"lg"} open={open}>
      <DialogBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "30px",
            paddingLeft: "30px",
            gap: "32px",
          }}
        >
          <DialogSubtitle>
            This product is exclusive to NOMA members.
          </DialogSubtitle>
          <Divider />
          <DialogText>
            <span>Please take a moment to verify your NOMA member email.</span>
          </DialogText>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px",
              }}
            >
              <TextField
                label={<Label text="Noma Email" required={true} />}
                name={"workEmail"}
                variant={"filled"}
                placeholder="John.doe@example.com"
                fullWidth={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <ButtonBox>
              {error && <div style={{ color: "red" }}>{error}</div>}
              {status === LoadingStatuses.Loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleSubmit}
                  disabled={!isValidEmail()}
                  children={"Verify Email"}
                  color={"primary"}
                  size={"medium"}
                />
              )}
            </ButtonBox>
          </div>
        </div>
      </DialogBox>
    </StyledDialog>
  )
}

export default View
