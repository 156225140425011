import React from "react"
import { FieldAttributes, useField } from "formik"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

import "./styles.css"

export type SelectOption = {
  label: string | number
  value: string | number
}

export type Props = {
  style?: React.CSSProperties
  blank?: string | undefined
  options: Array<SelectOption>
  onChange?: (value: string) => void
}

const SelectField = (props: Props & FieldAttributes<any>) => {
  const [field, meta, helpers] = useField(props)
  let hasError = !!(meta.touched && (meta.error || meta.value === ""))

  if (props.disabled) hasError = false

  const { setValue } = helpers
  const labelId = `${props.name}-label`

  const handleChange = (
    event: SelectChangeEvent<any>,
    child: React.ReactNode
  ) => {
    setValue(event.target.value)

    if (props.onChange) props.onChange(event.target.value as string)
  }

  return (
    <FormControl error={hasError} {...props} className="selectFormControl">
      <InputLabel id={labelId}>{props.label}</InputLabel>
      <Select
        labelId={labelId}
        id={props.name}
        value={field.value || ""}
        onChange={handleChange}
        style={props.style ? { ...props.style } : {}}
      >
        {props.blank && (
          <MenuItem value="" style={{ height: "48px" }}>
            <em>{props.blank}</em>
          </MenuItem>
        )}

        {props.options.map(({ value, label }: SelectOption) => (
          <MenuItem key={value} value={value} style={{ height: "48px" }}>
            {label}
          </MenuItem>
        ))}
      </Select>

      {hasError && (
        <FormHelperText style={{ color: "#f44336", fontSize: "14px" }}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectField
