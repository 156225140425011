import React, { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"
import OnboardingCheckPage from "../pages/OnboardingCheckPage"
import CheckoutPage from "../pages/CheckoutPage"
import Widget from "../pages/Widget"
import { MainContainer } from "../styles/global"
import { CommonNavbar } from "../components/Navbar"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CreateFirmDialog from "../components/CreateFirmDialog"
import { useSelector } from "react-redux"
import { selectMember } from "../redux/members"
import { AppState } from "../redux/configureStore"

export const DefaultRoutes = () => {
  const widgetUrls = ["/updateBilling", "/displayBillingInfo"]
  const [page, setPage] = useState(0)
  const member = useSelector(selectMember)
  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )
  const [isCreateCompanyDialogOpen, setCreateCompanyDialogOpen] =
    useState(false)

  useEffect(() => {
    if (
      member &&
      member.groupAccounts &&
      (member.groupAccounts.length === 0 || !member.groupAccounts[0].admin)
    ) {
      if (products && products.length > 0 && products[0].audience === "group") {
        setCreateCompanyDialogOpen(true)
      }
    }
  }, [member, products])

  // load navbar only for when user is directly on new checkout page
  // do not load for widget/iframe placed in EE
  return (
    <MainContainer>
      <ToastContainer position="bottom-right" />
      {!widgetUrls.includes(window.location.pathname) ? (
        <CommonNavbar setPage={setPage} />
      ) : null}
      <Routes>
        <Route
          path="/"
          element={<CheckoutPage page={page} setPage={setPage} />}
        />
        <Route
          path="/:id"
          element={<CheckoutPage page={page} setPage={setPage} />}
        />
        <Route path="/updateBilling" element={<Widget />} />
        <Route path="/displayBillingInfo" element={<Widget />} />
        <Route
          path="/authentication_callback"
          element={<OnboardingCheckPage />}
        />
      </Routes>
      <CreateFirmDialog
        open={isCreateCompanyDialogOpen}
        setOpen={setCreateCompanyDialogOpen}
      />
    </MainContainer>
  )
}
