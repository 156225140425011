import React from "react"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Dashboard from "@mui/icons-material/Dashboard"
import Logout from "@mui/icons-material/Logout"
import MenuIcon from "@mui/icons-material/Menu"
import { setCookie } from "../../utils/cookies"

export default function AccountMenu({ loggedIn }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // set cookie for ARE site to detect logout request
  const handleLogout = () => {
    setCookie("new_checkout_logout", "1")
    window.location.href = `${process.env.REACT_APP_ARE_URL}`
  }

  // Test comment
  const handleRedirect = () => {
    window.dataLayer.push({ event: "signIn" })
    setCookie("directToPaymentInfo", "1")

    let hostURL = `${process.env.REACT_APP_ARE_URL}`.split("/login")[0]

    window.location.href = `${hostURL}/landing/index.html?redirect=${hostURL}/login&postLogin=${window.location.href}`
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon sx={{ width: 32, height: 32 }}></MenuIcon>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {loggedIn ? (
          [
            <MenuItem
              onClick={() => {
                let url = process.env.REACT_APP_ARE_URL ?? ""
                let strippedUrl = url.endsWith("/login")
                  ? url.slice(0, -"/login".length)
                  : url
                window.location.href = `${strippedUrl}`
              }}
              key={"menu-item-1"}
            >
              <ListItemIcon>
                <Dashboard fontSize="small" />
              </ListItemIcon>
              Dashboard
            </MenuItem>,

            <MenuItem onClick={handleLogout} key={"menu-item-2"}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>,
          ]
        ) : (
          <MenuItem onClick={handleRedirect}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Log in
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}
